import React, {useState} from "react"
import Button from "../CustomButtons"
import {useAuth} from "react-oidc-context"
import CustomInput from "../CustomInput";
import {loginService} from "../../_services";
import {Alert} from "@mui/material";
import QueryString from "qs";

const RedirectLogin = () => {
  const storedEmail = localStorage.getItem("email")
  const auth = useAuth()
  const emailStart = readEmail()
  const [inputValue, setInputValue] = useState(emailStart ? emailStart : storedEmail); // State for input value
  const [errorMessage, setErrorMessage] = useState(''); // State for input value
  const [isBusy, setBusy] = useState(false);
  
  // Handler function for input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value); // Update state with new input value
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  }

  const handleLogin = () => {
    setBusy(true)
    localStorage.setItem("email", inputValue)
    loginService.getLoginMethod(inputValue, res=>{
      auth.signinPopup({popupWindowFeatures: {width: 450, height: 600, left: 150, top: 100}, login_hint: inputValue})
    }, error=>{
      setBusy(false)
      setErrorMessage("This application is only available to Octiva employees")
      setInputValue("")
    })
  }

  return (
    <div>
      <CustomInput
        labelText={"Email"}
        inputProps={{
          onChange: handleInputChange,
          type: "text",
          value: inputValue,
          onKeyUp: handleKeyUp
        }}
      />
      {errorMessage &&
          <Alert severity={"warning"}>{errorMessage}</Alert>
      }
      <Button color={isBusy ? "disabled" : "primary"} onClick={() => {
        handleLogin()
      }}>Log in</Button>
    </div>
  )
}

const readEmail = () => {
  const queries = QueryString.parse(window.location.search.substring(1))
  return queries.login_hint ? queries.login_hint.replace(" ", "+") : "";
}

export default RedirectLogin