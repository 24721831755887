import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/GridContainer"
import GridItem from "components/GridItem"
import Card from "components/Card"
import CardBody from "components/CardBody"
import CardHeader from "components/CardHeader"

// Assets
import userProfileStyles from "./userProfileStyles.jsx"

// Services
import {primaryColor} from "../../assets/jss/material-dashboard-pro-react";
import {connect} from "react-redux";
import DeviceSelector from "./DeviceSelector";
import LumionFixDetail from "./LumionFixDetail";
import DockingImplementDetail from "./DockingImplementDetail";
import XenionConnectDetail from "./XenionConnectDetail";
import XenionA6Detail from "./XenionA6Detail";


class DeviceDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.timer = null
    this._is_mounted = false
    this._refresh_rate = 3000
  }

  componentDidMount() {
    this._is_mounted = true
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this._is_mounted = false
  }

  render() {
    const {device_name, device_type} = this.props

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <DeviceSelector/>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <h4 style={{color: primaryColor}}><b>State Detail - {device_name}</b></h4>
            </CardHeader>
            <CardBody>
              {device_type === "LUMION_FIX" &&
                <LumionFixDetail/>
              }
              {device_type === "XENION_CONNECT" &&
                <XenionConnectDetail/>
              }
              {device_type === "XENION_A6" &&
                <XenionA6Detail/>
              }
              {device_type === "DIV01" &&
                <DockingImplementDetail/>
              }
              {!["LUMION_FIX", "DIV01", "XENION_CONNECT", "XENION_A6"].includes(device_type) &&
                <div>
                  The state visualisation for this device type ({device_type}) is not yet implemented.
                  <br/>
                  Contact your Nimbus manager to implement this.
                </div>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>)
  }
}

function mapStateToProps(state) {
  const {device_serial, device_state, all_devices} = state.device_fleet

  let device_name = ""
  all_devices.forEach((device)=>{
    if (device["serial"] === device_serial){
      device_name = device["name"]
    }
  })

  let device_type = ""
  if (state.device_fleet && state.device_fleet.all_commissioned_devices) {
    state.device_fleet.all_commissioned_devices.forEach((device) => {
      if (device_serial === device["serial"]) {
        device_type = device["device_type"]
      }
    })
  }
  return {
    device_serial, device_name, device_type, device_state
  }
}


export default withStyles(userProfileStyles)(connect(mapStateToProps)(DeviceDetail))
